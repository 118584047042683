import { Api } from 'utils';

class PaymentService {
	
	static public = {
		get: async (params?: any) => Api.createResponse('public/payment/',params),
		save: async (params?: any) => Api.createResponse('public/payment/save',params),
	}

}

export default PaymentService;