const contact_info = (state = {}, action: any) => {
	switch(action.type) {
		case 'SET_CONTACT_INFO':
            return action.payload;
            break;
        default: 
        	return state;
        	break;
	}
}

export default contact_info;