import React, { useEffect } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import {
  HashRouter as Router,
  Switch,
  Redirect,
  useHistory 
} from "react-router-dom";
import "moment/locale/es";
import { Route } from "components";
import { RootState } from "reducers";
import ScrollToTop from './scroll-to-top';

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ConversionService, AuthService, ProductService } from 'services';
import { Socket, SocketEvents, Constants } from 'utils';

// Public
import PublicHome from 'screens/public/home/home';
import PublicViewCart from 'screens/public/home/view-cart';
import PublicViewProduct from 'screens/public/home/view-product';
import PublicViewCategory from 'screens/public/home/view-category';
import PublicViewSearch from 'screens/public/home/view-search';
import PublicViewPayment from 'screens/public/home/view-payment';
import ClientValidate from 'screens/validate';

// Auth
import Login from "screens/auth/login/login";
import Recover from "screens/auth/login/recover";
import Reset from "screens/auth/login/reset";
import Register from "screens/auth/login/register";

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./scss/main.scss";

const Container = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const cart = useSelector((state: RootState) => state.cart);

  useEffect(() => {
    const get = async () => {
      const res: any = await ConversionService.admin.get({
        withoutLoading: true
      });
      if (res.conversion) {
        dispatch({
          type: 'SET_CONVERSION',
          payload: res.conversion
        });
      }
    }
    get();

    const getUser = async () => {
      if (user) {
        const res: any = await AuthService.checkUser({
          user_id: user.id,
          withoutLoading: true
        });
        if (res.user) {
          dispatch({
            type: 'SET_USER',
            payload: res.user
          });
          if (res.user?.verified == Constants.VALIDATION_STATUS.INACTIVE && res.user?.level_id == Constants.LEVELS.CLIENT) {
            history.replace('/client/validate');
          }
        }
      }
    }
    getUser();

    const updateCart = async () => {
      const res: any = await ProductService.public.updateCart({
        product_ids: JSON.stringify(cart?.map((i: any) => i.product_id)),
        withoutLoading: true
      });
      if (res.products) {
        res.products.forEach((item: any) => {
          const index = cart.findIndex((i: any) => i.product_id == item?.id);
          if (index != -1) {
            if (item?.deleted_at == null && item?.stock > 0) {
              cart[index].product = item;
            }
            else {
              cart.splice(index,1);
            }
          }
        });
        dispatch({
          type: 'SET_CART',
          payload: cart
        });
      }
    }
    updateCart();

    Socket.on(SocketEvents.CONVERSION.UPDATE,() => {
      get();
    });
  },[]);

  return (
    <React.Fragment>
      <ScrollToTop />
      <Switch>
        {/* Auth */}
        <Route exact noAuth path="/login" component={ Login } />
        <Route exact noAuth path="/register" component={ Register } />
        <Route exact noAuth path="/recover" component={ Recover } />
        <Route exact noAuth path="/recover/:code" component={ Reset } />

        {/* Public */}
        <Route exact path="/" component={ PublicHome } />
        <Route exact auth path="/client/validate" component={ ClientValidate } />
        <Route exact path="/view-cart" component={ PublicViewCart } />
        <Route exact auth path="/view-cart/payment" component={ PublicViewPayment } />
        <Route exact path="/view-product/:product_id" component={ PublicViewProduct } />
        <Route exact path="/view-search" component={ PublicViewSearch } />
        <Route exact path="/view-category/:product_type_id/:category_id?" component={ PublicViewCategory } />

        <Redirect to="/" />
      </Switch>
    </React.Fragment>
  )
}

const App = () => (
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <Router>
        <Container />
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
