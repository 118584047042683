export default {
	danger: '#e53935',
	green: '#43A047',
	yellow: '#e7b032',
	swal: '#00996b',
	orange: '#FB8C00',
	blue: '#394978',
	orangeTable: '#FFCC80',
	greenTable: '#8BC34A',
	redTable: '#EF5350',
	blueTable: '#42A5F5',
	white: '#fff'
}