import React from 'react';
import { Constants, Globals, Cart } from 'utils';
import { WhiteImage } from 'assets/img';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { CheckWhiteIcon, CloseIcon } from 'assets/icons';
import { Input } from 'components';

interface Props {
	item?: any;
	className?: string;
}
 
const ProductItem = (props: Props) => {
	const { item } = props;
	const cart = useSelector((state: RootState) => state.cart);
	const index_in_cart = cart.map((i: any) => i.product_id).indexOf(item?.id);
	const in_cart = index_in_cart != -1;
	const quantity_in_cart = cart[index_in_cart];
	const [quantity,setQuantity] = React.useState(quantity_in_cart?.quantity || 0);

	const add = (e: any) => {
		e.preventDefault();
		if (quantity < item?.stock) {
			setQuantity(quantity + 1);
		}
		else if (!in_cart && item?.stock > 0) {
			setQuantity(1);
		}
	}

	const remove = (e: any) => {
		e.preventDefault();
		if (quantity > 1) {
			setQuantity(quantity -1);
		}
		else if (quantity == 1) {
			setQuantity(0);
		}
	}

	return (
		<div className={ props.className || `item-product` }>
			<img src={ item?.preview_photo ? (Constants.BASE_STORAGE + item?.preview_photo) : (item?.photos?.length > 0 && item?.photos[0]?.file ? (Constants.BASE_STORAGE + item?.photos[0]?.file) : WhiteImage) } />
			<div>
				<div className="container-price">
					<h6>Splendor</h6>
					<h4>{ item?.name }</h4>
					<p className="price">{ Globals.formatMiles(item?.price) }</p>
				</div>
				<div className="container-buttons-add">
					<div style={{ flex: '.5' }}>
						<div className="container-add">
							<button className="btn-sub-quantity" onClick={ (e: any) => remove(e) }>
								<p>-</p>
							</button>
							<Input
								color="white"
								// value={ quantity_in_cart?.quantity || 0 }
								value={ quantity }
								name="quantity" 
								placeholder=""
								type="number"
								disabled
								onChange={ (e: any) => {} } />
							<button className="btn-add-quantity" onClick={ (e: any) => add(e) }>
								<p>+</p>
							</button>
						</div>
					</div>
					<div style={{ flex: '.5' }}>
						{
							item?.stock > 0 ? (
								<button onClick={ (e: any) => {
									e.preventDefault();
									e.stopPropagation();
									if (in_cart) {
										if (quantity != quantity_in_cart?.quantity) {
											if (quantity > 0) {
												Cart.add(quantity,item);
											}
											else {
												Cart.remove(item?.id);
											}
										}
										else {
											Cart.remove(item?.id);
											setQuantity(0);
										}
									}
									else {
										if (quantity > 0) {
											Cart.add(quantity,item);
										}
										else {
											Cart.remove(item?.id);
										}
									}
								} }>
									{ in_cart && <img src={ quantity != quantity_in_cart?.quantity ? CheckWhiteIcon : CloseIcon } /> } { in_cart ? (quantity != quantity_in_cart?.quantity ? 'Guardar' : 'Eliminar') : 'Añadir' }
								</button>
							) : (
								<p className="existence">Agotado</p>
							)
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProductItem;