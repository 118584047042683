import { Api } from 'utils';

class ProfileService {
	
	static get = async (params?: any) => Api.createResponse('profile/get',params);
	static update = async (params?: any) => Api.createResponse('profile/save',params);
	static password = async (params?: any) => Api.createResponse('profile/password',params);
	static updateAddress = async (params?: any) => Api.createResponse('client/profile/update-splendor-addresses',params);
	static getAddresses = async (params?: any) => Api.createResponse('client/profile/splendor-addresses',params);

}

export default ProfileService;