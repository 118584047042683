import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import Layout from 'screens/public/layout/layout';
import { Constants, Globals, Cart } from 'utils';
import { WhiteImage } from 'assets/img';
import NavigationBar from '../layout/navigation-bar';
import { CloseIcon } from 'assets/icons';
import { Input } from 'components';
import { ProductService } from 'services';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class ViewCart extends React.Component<Props> {

	state = {
		loading: true,
		iva: 0
	}

	componentDidMount() {
		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ProductService.public.updateCart({
			product_ids: JSON.stringify(this.props.cart?.map((i: any) => i.product_id))
		});
		if (res.products) {
			let cart = [...this.props.cart];
			res.products.forEach((item: any) => {
				const index = cart.findIndex((i: any) => i.product_id == item?.id);
				if (index != -1) {
					if (item?.deleted_at == null && item?.stock > 0) {
						cart[index].product = item;
					}
					else {
						cart.splice(index,1);
					}
				}
			});
			this.props.dispatch({
				type: 'SET_CART',
				payload: cart
			});
		}
		if (res.iva) {
			this.setState({
				iva: res.iva || 0
			});
		}
	}

	goToPayment = () => {
		const item = this.props.cart.find((i: any) => !i.quantity || i.quantity <= 0);
		if (item != null) {
			Globals.showError("Lo sentimos, la cantidad indicada del producto '" + item?.product?.name + "' no es válida");
			return;
		}

		this.props.cart.forEach((i: any) => {
			if (i?.product?.stock < i.quantity) {
				Globals.showError(`Lo sentimos, el producto ${ i?.product?.name } no posee suficiente existencia`);
				return;
			}
		});

		this.props.history.replace('/view-cart/payment');
	}

	render() {
		const total = this.props.cart.map((i: any) => i.product?.price * i.quantity).reduce((a: number, b: number) => a + b,0);
		const iva = total * (this.state.iva / 100);

		return (
			<Layout>
				<div id="public-view-cart">
					<NavigationBar
						items={[
							{ label: 'Inicio', url: '/' },
							{ label: 'Carrito' }
						]}
					/>

					<div className="container-cart">
						<h3>Carrito</h3>
						<div className="row">
							<div className="col-md-8">
								{
									this.props.cart.length == 0 && (
										<p className="no-items">No se han agregado productos al carrito</p>
									)
								}

								{
									this.props.cart.length > 0 && (
										<table className="table-products">
											<tr>
												<th></th>
												<th style={{ width: '40%' }}>Producto</th>
												<th className="td-responsive">Precio unitario</th>
												<th>Cantidad</th>
												<th>Subtotal</th>
											</tr>
											{
												this.props.cart.map((item: any) => {
													return (
														<tr>
															<td className="text-center">
																<button onClick={ () => Cart.remove(item?.product?.id) }>
																	<img src={ CloseIcon } />
																</button>
															</td>
															<td>
																<div className="container-photo">
																	<img src={ item?.product?.preview_photo ? (Constants.BASE_STORAGE + item?.product?.preview_photo) : (item?.product?.photos?.length > 0 && item?.product?.photos[0]?.file ? (Constants.BASE_STORAGE + item?.product?.photos[0]?.file) : WhiteImage) } />
																	{ item?.product?.name }
																</div>
															</td>
															<td className="price td-responsive">{ Globals.formatMiles(item?.product?.price) }</td>
															<td className="text-center">
																<Input
																	color="white"
																	value={ item?.quantity }
																	name="quantity" 
																	placeholder=""
																	type="number"
																	onChange={ (e: any) => {
																		if (e.target.value !== null && e.target.value !== '' && e.target.value > 0 && parseInt(e.target.value) <= item?.product?.stock) {
																			Cart.add(e.target.value,item?.product);
																		}
																	} } />
															</td>
															<td className="price">{ Globals.formatMiles(item?.product?.price * item?.quantity) }</td>
														</tr>
													)
												})
											}
										</table>
									)
								}
							</div>
							<div className="col-md-4">
								<div className="container-total">
									<h4>Total</h4>
									<div>
										<table>
											<tr>
												<td>Subtotal</td>
												<td className="text-right">{ Globals.formatMiles(total) }</td>
											</tr>
											<tr>
												<td>IVA ({ this.state.iva }%)</td>
												<td className="text-right">{ Globals.formatMiles(iva) }</td>
											</tr>
											<tr>
												<td>Total</td>
												<td className="text-right">{ Globals.formatMiles(total + iva) }</td>
											</tr>
										</table>
										{
											this.props.cart.length > 0 && (
												<div className="container-padding">
													<button className="btn-green" onClick={ () => this.goToPayment() }>
														Procesar Pago
													</button>
												</div>
											)
										}
									</div>
								</div>
								<div className="text-center">
									<button className="btn-back" onClick={ () => this.props.history.replace('/') }>
										Seguir Comprando
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user,
		cart: state.cart
	}
})(ViewCart);