import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import Layout from 'screens/public/layout/layout';
import { ProductService } from 'services';
import { Constants, Globals, Cart } from 'utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-element.css';
import { Pagination, Autoplay } from 'swiper';
import { WhiteImage } from 'assets/img';
import { ArrowLeftBlack, ArrowRightBlack, CheckWhiteIcon, ShareIcon } from 'assets/icons';
import NavigationBar from '../layout/navigation-bar';
import ProductSlider from '../layout/product-slider';
import SliderSecondary from '../layout/slider-secondary';
import ReactImageMagnify from 'react-image-magnify';
import { Input } from 'components';

interface MatchParams {
	product_id: string
}

interface Props extends RootState, RouteComponentProps<MatchParams> {
	dispatch: any
}

class ViewProduct extends React.Component<Props> {

	swiper: any = null;

	state = {
		loading: true,
		item: null,
		selected_photo: null,
		form: {
			quantity: 1
		}
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(prevProps: Props) {
	  if (prevProps.match.params.product_id !== this.props.match.params.product_id) {
	    this.load();
	  }
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ProductService.public.view({
			product_id: this.props.match.params.product_id
		});
		if (res.product) {
			this.setState({
				item: res.product,
				selected_photo: res.product?.photos?.length > 0 ? res.product?.photos[0]?.file : null
			},() => {
				const element = document.getElementById('public-view-product');
			    if (element) {
			      element.scrollIntoView({ behavior: 'smooth' });
			    }
			    const item_in_cart = this.props.cart.find((i: any) => i.product_id == res.product?.id);
			    this.setState({
			    	form: {
			    		...this.state.form,
			    		quantity: item_in_cart?.quantity || 1
			    	}
			    });
			});
		}
		else {
			this.props.history.replace('/');
		}
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	add = () => {
		let quantity = this.state.form.quantity;
		const item: any = this.state.item;
		if (quantity < item?.stock) {
			quantity++
			this.setState({
				form: {
					...this.state.form,
					quantity
				}
			});
		}
	}

	remove = () => {
		let quantity = this.state.form.quantity;
		if (quantity > 1) {
			quantity--;
			this.setState({
				form: {
					...this.state.form,
					quantity
				}
			});
		}
	}

	render() {
		const item: any = this.state.item;
		const in_cart = this.props.cart.map((i: any) => i.product_id).indexOf(item?.id) != -1;
		const item_in_cart = this.props.cart.find((i: any) => i.product_id == item?.id);

		return (
			<Layout>
				<div id="public-view-product">
					<NavigationBar
						items={[
							{ label: 'Inicio', url: '/' },
							{ label: item?.name }
						]}
					/>

					<div className="row row-info">
						<div className="col-md-5 text-center">
							<div className="container-image">
								<ReactImageMagnify {...{
									enlargedImageContainerDimensions: {
										width: '180%',
										height: '100%'
									},
									enlargedImageContainerClassName: 'large-image',
								    smallImage: {
								        isFluidWidth: false,
								        width: 370,
								        height: 370,
								        // @ts-ignore
								        src: Constants.BASE_STORAGE + this.state.selected_photo
								    },
								    largeImage: {
								    	// @ts-ignore
								        src: Constants.BASE_STORAGE + this.state.selected_photo,
								        width: 1080,
    									height: 1080
								    }
								}} />
							</div>
							<div className="image-responsive">
								{/*
								// @ts-ignore */}
								<img src={ Constants.BASE_STORAGE + this.state.selected_photo } />
							</div>
							<div className="container-photos">
								<button onClick={ () => this.swiper.slidePrev() }>
									<img src={ ArrowLeftBlack } />
								</button>
								<Swiper
									onSwiper={ (swiper) => this.swiper = swiper }
									slidesPerView={ 'auto' }
									breakpoints={{
									    600: {
									      slidesPerView: 2
									    },
									    991: {
									      slidesPerView: 3
									    }
									}}>
									{
										item?.photos?.map((item: any) => {
											return (
												<SwiperSlide className="slide-photos">
													<a href="#" onClick={ (e: any) => {
														e.preventDefault();
														this.setState({
															selected_photo: item?.file
														});
													} }>
								                    	<img className="image-preview" src={ Constants.BASE_STORAGE + item?.file } />
								                    </a>
								                </SwiperSlide>
											)
										})
									}
							    </Swiper>
								<button onClick={ () => this.swiper.slideNext() }>
									<img src={ ArrowRightBlack } />
								</button>
							</div>
						</div>
						<div className="col-md-7">
							<p className="page-name">Splendor</p>
							<h3>{ item?.name }</h3>
							{/*<button className="btn-share" onClick={ () => {
								// @ts-ignore
								navigator.share({
							        title: "¡Echa un vistazo a este producto en Splendor!",
							        url: window.location.href
							    });
							} }>
								<img src={ ShareIcon } />
								Compartir
							</button>*/}
							<p className="price">{ Globals.formatMiles(item?.price) }</p>
							<hr />
							<p className="description">{ item?.description }</p>
							{/*<hr />
							<h4>Precio</h4>
							<p className="total">{ Globals.formatMiles(this.state.form.quantity * item?.price) }</p>
							<p className={ `existence ${ item?.stock == 0 ? 'out' : '' }` }>Existencia: { item?.stock }</p>*/}
							{
								item?.stock > 0 && (
									<div className="container-quantity">
										<div className="container-add">
											<button className="btn-sub-quantity" onClick={ () => this.remove() }>
												<p>-</p>
											</button>
											<Input
												color="white"
												value={ this.state.form.quantity }
												name="quantity" 
												placeholder=""
												type="number"
												onChange={ (e: any) => {
													if (e.target.value !== null && e.target.value !== '' && e.target.value > 0) {
														this.change(e);
													}
												} } />
											<button className="btn-add-quantity" onClick={ () => this.add() }>
												<p>+</p>
											</button>
										</div>
										<button className="btn-add" onClick={ (e: any) => {
											e.preventDefault();
											e.stopPropagation();
											if (!this.state.form.quantity || this.state.form.quantity <= 0) {
												Globals.showError("Lo sentimos, la cantidad indicada no es válida");
												return;
											}
											if (in_cart && item_in_cart?.quantity == this.state.form.quantity) {
												Cart.remove(item?.id);
												this.setState({
													form: {
														quantity: 1
													}
												});
											}
											else {
												Cart.add(this.state.form.quantity,item);
											}
										} }>
											{ in_cart && item_in_cart?.quantity == this.state.form.quantity && <img src={ CheckWhiteIcon } /> } { in_cart ? (item_in_cart?.quantity != this.state.form.quantity ? 'Guardar' : 'Añadido') : 'Agregar al Carrito' }
										</button>
									</div>
								)
							}
						</div>
					</div>

					<SliderSecondary />
					<ProductSlider />
				</div>
			</Layout>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user,
		banners: state.banners,
		cart: state.cart
	}
})(ViewProduct);