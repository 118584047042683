import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import Layout from 'screens/public/layout/layout';
import { Constants, Globals } from 'utils';
import { WhiteImage } from 'assets/img';
import { Pagination } from 'components';
import { ProductService } from 'services';
import ProductItem from '../layout/product-item';
import ProductSlider from '../layout/product-slider';
import SliderSecondary from '../layout/slider-secondary';
import NavigationBar from '../layout/navigation-bar';
import queryString from 'query-string';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class ViewSearch extends React.Component<Props> {

	state = {
		loading: true,
		page: 1,
		last_page: 1,
		data: []
	}

	componentDidMount() {
		const {
			search
		} = queryString.parse(this.props.location.search);

		if (search) {
			this.load();
		}
		else {
			this.props.history.replace('/');
		}
	}

	load = async () => {
		const {
			search
		} = queryString.parse(this.props.location.search);

		await this.setState({
			loading: true
		});
		const res: any = await ProductService.public.search({
			withoutLoading: true,
			page: this.state.page,
			search
		});
		this.setState({
			data: res.products.rows,
			last_page: res.products.count,
			loading: false
		});
	}

	render() {
		return (
			<Layout>
				<div id="public-view-search">
					<NavigationBar
						items={[
							{ label: 'Inicio', url: '/' },
							{ label: 'Resultados de la Búsqueda' }
						]}
					/>

					<div className="container-search">
						<h3 className="title-green">Resultados de la Búsqueda</h3>

	            		{
	            			this.state.loading ? (
	            				<div className="container-loading">
	            					<i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
	            				</div>
	            			) :  (
	            				<div className="row row-products">
	            					{
				            			this.state.data.map((item: any) => {
				            				return (
				            					<div className="col-md-3">
				            						<Link to={ `/view-product/${ item?.id }` } className="link-product">
						            					<ProductItem item={ item } />
						            				</Link>
				            					</div>
				            				)
				            			})
				            		}

				            		{
				            			this.state.data.length == 0 && (
				            				<p className="message-no-items">Lo sentimos, no se han encontrado productos con estas especificaciones</p>
				            			)
				            		}
	            				</div>
	            			)
	            		}

	            		<Pagination 
							pages={ this.state.last_page } 
							active={ this.state.page }
							onChange={ async (page: number) => {
								await this.setState({
									page: page
								});
								this.load();
							} } />
					</div>

					<SliderSecondary />
					<ProductSlider />
				</div>
			</Layout>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(ViewSearch);