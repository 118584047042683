import React from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-element.css';
import { Pagination, Autoplay } from 'swiper';
import { RootState } from 'reducers';
import { Link } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { WhiteImage } from 'assets/img';
import { ArrowLeftBlack, ArrowRightBlack } from 'assets/icons';
import ProductItem from './product-item';

const ProductSlider = () => {
	const last_products = useSelector((state: RootState) => state.last_products);
	const [swiper, setSwiper]: any = React.useState(null);

	return (
		<div id="public-product-slider">
			<div className="container-title-slide">
				<h3>Productos</h3>
				<div>
					<button onClick={ () => swiper.slidePrev() }>
						<img src={ ArrowLeftBlack } />
					</button>
					<button onClick={ () => swiper.slideNext() }>
						<img src={ ArrowRightBlack } />
					</button>
				</div>
			</div>

			{
				last_products.length > 0 && (
					<Swiper
						slidesPerView={ 'auto' }
					    loop={ false }
				        autoplay={{
				            delay: 3000,
				            disableOnInteraction: false
				        }}
				        breakpoints={{
				        	400: {
						      slidesPerView: 1
						    },
						    600: {
						      slidesPerView: 2
						    },
						    991: {
						      slidesPerView: 3
						    },
						    1100: {
						      slidesPerView: 5
						    }
						}}
				        onSwiper={ (swiper) => setSwiper(swiper) }
						modules={[ Autoplay ]}>
							{
								last_products.map((item: any) => {
		            				return (
		            					<SwiperSlide>
			        						<Link to={ `/view-product/${ item?.id }` } className="link-product">
				            					<ProductItem item={ item } />
				            				</Link>
			            				</SwiperSlide>
		            				)
		            			})
							}
				    </Swiper>
				)
			}
		</div>
	)
}

export default ProductSlider;