import { store } from 'store';

const add = (quantity: number, product: any) => {
	let cart: any = [ ...store.getState().cart ];
	const index = cart.findIndex((i: any) => i.product_id == product?.id);
	if (index == -1) {
		cart.push({
			quantity,
			product,
			product_id: product?.id
		});
	}
	else {
		cart[index] = {
			quantity,
			product,
			product_id: product?.id
		};
	}
	store.dispatch({
		type: 'SET_CART',
		payload: cart
	});
}

const remove = (product_id = null) => {
	let cart: any = [ ...store.getState().cart ];
	const index = cart.findIndex((i: any) => i.product_id == product_id);
	cart.splice(index,1);
	store.dispatch({
		type: 'SET_CART',
		payload: cart
	});
}

const destroy = () => {
	store.dispatch({
		type: 'SET_CART',
		payload: []
	});
}

export default {
	add,
	remove,
	destroy
}