import React from 'react';
import { Input, Button, Select } from 'components';
import { connect } from 'react-redux';
import { AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router';
import { BgLogin, LoginImage, LoginImage2 } from 'assets/img';
import { ArrowLeft } from 'assets/icons';
import { Link } from 'react-router-dom';
import { Globals, Constants } from 'utils';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Register extends React.Component<Props> {

	state = {
		form: {
			name: '',
	        email: '',
	        lastname: '',
	        password: '',
	        document: '',
	        phone: '',
	        tax_document: '',
	        password_confirmation: '',
	        document_type_id: Constants.DOCUMENT_TYPES.V
		},
		document_types: []
	}

	componentDidMount() {
		if (this.props.user) {
			this.props.history.replace('/');
		}

		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Formulario Registro',
				onBack: null
			}
		});

		this.load();
	}

	load = async () => {
		const res: any = await AuthService.getDocumentTypes();
		this.setState({
			document_types: res.document_types
		});
	}

	submit = async (e: any) => {
		e.preventDefault();

		if (!this.state.form.document && [Constants.DOCUMENT_TYPES.V, Constants.DOCUMENT_TYPES.E].indexOf(this.state.form.document_type_id) != -1) {
			Globals.showError("El campo cédula es requerido");
			return;
		}

		if (!this.state.form.tax_document && [Constants.DOCUMENT_TYPES.J, Constants.DOCUMENT_TYPES.G].indexOf(this.state.form.document_type_id) != -1) {
			Globals.showError("El campo RIF es requerido");
			return;
		}

		const res: any = await AuthService.register({
			...this.state.form,
			registered_in_id: Constants.PAGES.SPLENDOR
		});
		await this.props.dispatch({
			type: 'SET_USER',
			payload: res.user
		});
		this.props.history.replace('/');
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="register">
				<div className="container-black">
					<div className="row container-form">
						<div className="col-lg-5 container-bg" style={{ backgroundImage: `url(${ LoginImage2 })` }}></div>
						<form className="col-lg-7" onSubmit={ this.submit }>
							<div className="row-title">
								<Link to="/login">
									<img src={ ArrowLeft } className="arrow-left-green" />
								</Link>
								<h3 className="title-green">Formulario Registro</h3>
							</div>
							<h3 className="subtitle">Datos de persona de contacto o empresa</h3>
							<div className="row">
								<div className="col-md-6">
									<Input
										color="white"
										value={ this.state.form.name }
										name="name" 
										placeholder="Nombre"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<Input
										color="white"
										value={ this.state.form.lastname }
										name="lastname" 
										placeholder="Apellido"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<div className="container-document">
										<div className="select-document">
											<Select
												placeholder="Tipo de Documento"
												name="document_type_id"
												onChange={ (e: any) => {
													this.setState({
														form: {
															...this.state.form,
															document_type_id: parseInt(e.target.value)
														}
													});
												} }
												value={ this.state.form.document_type_id }
												options={ this.state.document_types.map((i: any) => {
													return {
														value: i.id,
														label: i.name
													}
												}) } />
										</div>
										<div>
											{
												[Constants.DOCUMENT_TYPES.V, Constants.DOCUMENT_TYPES.E].indexOf(this.state.form.document_type_id) != -1 && (
													<Input
														color="white"
														value={ this.state.form.document }
														name="document" 
														placeholder="Cédula"
														onChange={ this.change } />
												)
											}
											{
												[Constants.DOCUMENT_TYPES.J, Constants.DOCUMENT_TYPES.G].indexOf(this.state.form.document_type_id) != -1 && (
													<Input
														color="white"
														value={ this.state.form.tax_document }
														name="tax_document" 
														placeholder="RIF"
														onChange={ this.change } />
												)
											}
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<Input
										color="white"
										value={ this.state.form.phone }
										name="phone" 
										placeholder="Teléfono - Ej. 04121234567"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<Input
										color="white"
										value={ this.state.form.email }
										name="email" 
										placeholder="Correo"
										onChange={ this.change } />
								</div>
							</div>
							<h3 className="subtitle">Contraseña</h3>
							<div className="row">
								<div className="col-md-6">
									<Input
										type="password"
										color="white"
										value={ this.state.form.password }
										name="password" 
										placeholder="Contraseña"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<Input
										type="password"
										color="white"
										value={ this.state.form.password_confirmation }
										name="password_confirmation" 
										placeholder="Repetir Contraseña"
										onChange={ this.change } />
								</div>
							</div>
							<div className="container-input container-btn-submit text-right">
								<Button type="submit">
									Continuar
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Register);