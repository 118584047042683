export default {
	BASE_STORAGE: process.env.REACT_APP_BASE_STORAGE,
	PUBLIC_WEB: process.env.REACT_APP_PUBLIC_WEB,
	BASE_API: process.env.REACT_APP_BASE_API,
	BASE_PUBLIC: process.env.REACT_APP_BASE_PUBLIC,
	TIMEZONE: 'America/Caracas',
	LEVELS: {
		ADMIN: 1,
		OPERATOR: 2,
		CLIENT: 3,
		MODERATOR: 4
	},
	CURRENCIES: {
		DOLAR: 1,
		BOLIVAR: 2
	},
	MODULES: {
		MODERATORS: 1,
		SERVICES: 2,
		OPERATORS: 4,
		EXCHANGE: 5,
		QUOTES: 6,
		REPORTS: 7,
		CLIENTS: 8,
		BANK_ACCOUNTS: 9,
		DISCOUNTS: 10,
		SHOP: 11
	},
	SERVICE_STATUS: {
		ACTIVE: 1,
		INACTIVE: 0
	},
	QUOTE_STATUS: {
	    PENDING: 1,
	    ASSIGNED: 2,
	    IN_PROGRESS: 3,
	    FINISH: 4,
	    CANCELLED: 5
	},
	PAYMENT_METHODS: {
	    MOVIL: 1,
	    ZELLE: 2,
	    TRANSFER: 3,
	    BFC: 4,
	    CASH: 5
	},
	CURRENCIES_SYMBOL: {
		BOLIVAR: 'Bs.',
		DOLAR: '$'
	},
	JOURNEYS: {
		MORNING: 1,
		AFTERNOON: 2,
		FULL: 3
	},
	QUOTE_PAYMENT_STATUS: {
	    PENDING: 0,
	    APPROVED: 1,
	    REJECTED: 2
	},
	DISCOUNT_STATUS: {
	    ACTIVE: 1,
	    INACTIVE: 0
  	},
  	DOCUMENT_TYPES: {
  		V: 1,
		E: 2,
		J: 3,
		G: 4
  	},
  	PAGES: {
	    SERVIZI: 1,
	    SPLENDOR: 2
	},
	BANNERS: {
	    PRINCIPAL: 1,
	    SECONDARY: 2,
	    ADVERTISING: 3
	},
	PRODUCT_TYPES: {
		PRODUCT: 1,
		COMBO: 2
	},
	PRODUCT_STATUS: {
	    ACTIVE: 1,
	    INACTIVE: 0
	},
	DELIVERY_TYPES: {
	    PICKUP: 1,
	    DELIVERY: 2
	},
	ORDERS_STATUS: {
	    PENDING: 0
	},
	VALIDATION_STATUS: {
	    INACTIVE: 0,
	    ACTIVE: 1
	}
}