import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
	items: any[];
}

const NavigationBar = (props: Props) => {
	return (
		<div className="navigation-bar">
			{ 
				props.items.map((item,index) => {
					return (
						<React.Fragment>
							<div className="item-navigation">
								<Link to={ item?.url } onClick={ (e: any) => {
									if (!item?.url) {
										e.preventDefault();
									}
								} }>
									<p className={ (index + 1) == props.items.length ? 'bold' : '' }>{ item?.label }</p>
								</Link>
							</div>
							{
								(index + 1) < props.items.length && (
									<div className="item-navigation">
										<p>{ '>' }</p>
									</div>
								)
							}
						</React.Fragment>
					)
				}) 
			}
		</div>
	)
}

export default NavigationBar;