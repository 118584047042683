import React from 'react';
import { Input, Button } from 'components';
import { ProfileService } from 'services';
import moment from 'moment';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { Globals } from 'utils';
import { RouteComponentProps } from 'react-router-dom';
import { PasswordImage } from 'assets/img';

const INITIAL_STATE = {
	password: '',
	password_confirmation: '',
	current_password: ''
}

interface Props extends RootState, RouteComponentProps {
	dispatch: any;
	onClose?: any;
}

class Password extends React.Component<Props> {

	state = {
		form_password: INITIAL_STATE
	}

	submitPassword = async (e: any) => {
		e.preventDefault();
		await ProfileService.password({
			user_id: this.props.user.id,
			...this.state.form_password
		});
		this.setState({
			form_password: INITIAL_STATE
		});
		Globals.showSuccess("Se ha actualizado la contraseña correctamente");
		this.props.onClose();
	}

	changePassword = (e: any) => {
		this.setState({
			form_password: {
				...this.state.form_password,
				[e.target.name]: e.target.value
			}
		});
	}
	
	render() {
		return (
			<div id="password">
				<div className="row">
					<div className="col-md-6 text-center">
						<img src={ PasswordImage } className="image-left" />
					</div>
					<div className="col-md-6">
						<h3>Cambio de contraseña</h3>
						<form className="row" onSubmit={ this.submitPassword }>
							<div className="col-12">
								<Input
									type="password"
									color="white"
									value={ this.state.form_password.current_password }
									name="current_password" 
									label="Contraseña Actual"
									placeholder="Contraseña"
									onChange={ this.changePassword } />
							</div>
							<div className="col-12">
								<Input
									type="password"
									color="white"
									value={ this.state.form_password.password }
									name="password" 
									label="Nueva Contraseña"
									placeholder="Contraseña"
									onChange={ this.changePassword } />
							</div>
							<div className="col-12">
								<Input
									type="password"
									color="white"
									value={ this.state.form_password.password_confirmation }
									name="password_confirmation" 
									label="Confirmar Contraseña"
									placeholder="Contraseña"
									onChange={ this.changePassword } />
							</div>
							<div className="row col-12 text-center">
								<div className="col-md-6 text-center">
									<Button type="button" className="btn-close" onClick={ () => this.props.onClose() }>
										Cerrar
									</Button>
								</div>
								<div className="col-md-6 text-center">
									<Button type="submit" className="btn-green">
										Guardar
									</Button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Password);