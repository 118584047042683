import React from 'react';
import { Input, Textarea, Select, Button } from 'components';
import { ProfileService } from 'services';
import moment from 'moment';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { Globals, Constants } from 'utils';
import { RouteComponentProps } from 'react-router-dom';
import { PasswordImage } from 'assets/img';

interface Props extends RootState, RouteComponentProps {
	dispatch: any;
	onClose?: any;
}

class Profile extends React.Component<Props> {

	state = {
		form: {
			name: '',
	        email: '',
	        lastname: ''
		}
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await ProfileService.get({
			user_id: this.props.user.id
		});
		this.setState({
			form: {
				name: res.user.name,
				lastname: res.user.lastname,
		        email: res.user.email
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		const res: any = await ProfileService.update({
			user_id: this.props.user.id,
			...this.state.form
		});
		Globals.showSuccess("Se ha actualizado el perfil correctamente");
		this.props.dispatch({
			type: 'SET_USER',
			payload: res.user
		});
		this.props.onClose();
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="profile">
				<div className="row">
					<div className="col-md-6 text-center">
						<img src={ PasswordImage } className="image-left" />
					</div>
					<div className="col-md-6">
						<h3>Mis Datos</h3>
						<form className="row" onSubmit={ this.submit }>	
							<div className="col-12">
								<Input
									color="white"
									value={ this.state.form.name }
									name="name" 
									label="Nombre"
									onChange={ this.change } />
							</div>
							<div className="col-12">
								<Input
									color="white"
									value={ this.state.form.lastname }
									name="lastname" 
									label="Apellido"
									onChange={ this.change } />
							</div>
							<div className="col-12">
								<Input
									color="white"
									value={ this.state.form.email }
									name="email" 
									label="Correo Electrónico"
									onChange={ this.change } />
							</div>			
							<div className="row col-12 text-center">
								<div className="col-md-6 text-center">
									<Button type="button" className="btn-close" onClick={ () => this.props.onClose() }>
										Cerrar
									</Button>
								</div>
								<div className="col-md-6 text-center">
									<Button type="submit" className="btn-green">
										Guardar
									</Button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Profile);