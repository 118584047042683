import { Api } from 'utils';

class ProductService {

	static public = {
		view: async (params?: any) => Api.createResponse('public/products/view',params),
		autocomplete: async (params?: any) => Api.createResponse('public/products/autocomplete',params),
		search: async (params?: any) => Api.createResponse('public/products/search',params),
		viewCategory: async (params?: any) => Api.createResponse('public/products/category',params),
		updateCart: async (params?: any) => Api.createResponse('public/products/cart',params),
	}

}

export default ProductService;