import { Api } from 'utils';

class ConversionService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/conversion/get',params)
	}

}

export default ConversionService;