import React from 'react';
import { Constants } from 'utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-element.css';
import { Pagination, Autoplay } from 'swiper';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { useSelector } from 'react-redux';
 
const SliderSecondary = () => {
	const banners = useSelector((state: RootState) => state.banners);
	const secondary_banners = banners.filter((i: any) => i.type == Constants.BANNERS.SECONDARY);

	return (
		<div className="container-slider-secondary">
			{
				secondary_banners.length > 0 && (
					<Swiper
						slidesPerView={ 1 }
						pagination={{
					       clickable: true
					    }}
					    loop={true}
				        autoplay={{
				            delay: 3000,
				            disableOnInteraction: false
				        }}
							modules={[ Pagination, Autoplay ]}>
						{
							secondary_banners.map((item: any) => {
								return (
									<SwiperSlide>
										<a href={ item?.url } target="_blank" onClick={ (e: any) => {
											if (!item?.url) {
												e.preventDefault();
											}
										} }>
					                    	<img className="item-secondary" src={ Constants.BASE_STORAGE + item?.file } />
					                    </a>
					                </SwiperSlide>
								)
							})
						}
				    </Swiper>
				)
			}
		</div>
	)
}

export default SliderSecondary;