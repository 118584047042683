import { Api } from 'utils';

class HomeService {
	
	static public = {
		getContact: async (params?: any) => Api.createResponse('public/home/get-contact',params),
		get: async (params?: any) => Api.createResponse('public/home',params),
		getProducts: async (params?: any) => Api.createResponse('public/home/products',params),
	}

}

export default HomeService;