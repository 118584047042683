const banners = (state = [], action: any) => {
	switch(action.type) {
		case 'SET_BANNERS':
            return action.payload;
            break;
        default: 
        	return state;
        	break;
	}
}

export default banners;