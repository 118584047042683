import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    const element = document.getElementsByTagName('body');
    if (element && element[0]) {
      element[0].scrollIntoView({ behavior: 'smooth' });
    }
  }, [pathname]);

  return null;
}

export default ScrollToTop;