import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import Layout from 'screens/public/layout/layout';
import { Constants, Globals } from 'utils';
import { WhiteImage, HeaderImage, BtnHeaderImage } from 'assets/img';
import SliderPrimary from '../layout/slider-primary';
import { ProductService } from 'services';
import ProductItem from '../layout/product-item';
import { Pagination } from 'components';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

interface MatchParams {
	category_id: string;
	product_type_id: string;
}

interface Props extends RootState, RouteComponentProps<MatchParams> {
	dispatch: any
}

class ViewCategory extends React.Component<Props> {

	timer: any = undefined;

	state = {
		loading: true,
		page: 1,
		last_page: 1,
		data: [],
		since: 0,
		until: 0,
		price_filter: null
	}

	async componentDidMount() {
		this.load();
	}

	async componentDidUpdate(prevProps: Props) {
	  if (prevProps.match.params.product_type_id !== this.props.match.params.product_type_id || prevProps.match.params.category_id !== this.props.match.params.category_id) {
	  	await this.setState({
	  		page: 1,
	  		last_page: 1,
	  		price_filter: null
	  	});
	    await this.load();
	    const element = document.getElementById('container-products');
	    if (element) {
	      element.scrollIntoView({ behavior: 'smooth' });
	    }
	  }
	}

	load = (withoutLoading: boolean = false) => {
		return new Promise(async (resolve,reject) => {
			if (!withoutLoading) {
				await this.setState({
					loading: true
				});
			}
			const res: any = await ProductService.public.viewCategory({
				product_type_id: this.props.match.params.product_type_id,
				category_id: this.props.match.params.category_id,
				page: this.state.page,
				withoutLoading: true,
				// @ts-ignore
				since: this.state.price_filter != null ? this.state.price_filter[0] : null,
				// @ts-ignore
				until: this.state.price_filter != null ? this.state.price_filter[1] : null,
			});
			const element = document.getElementById('container-products');
		    if (element) {
		      element.scrollIntoView({ behavior: 'smooth' });
		    }
			this.setState({
				data: res.products.rows,
				last_page: res.products.count,
				loading: false,
				since: res.since || 0,
				until: res.until || 0,
				price_filter: this.state.price_filter == null ? [res.since,res.until] : this.state.price_filter
			},() => {
			    resolve(true);
			});
		});
		
	}

	render() {
		return (
			<Layout>
				<div id="public-view-category">
					{/*<SliderPrimary />*/}

					<div className="row row-products">
						<div className="col-md-3">
							<div className="container-filter">
								<div className="container-item-filter">
									<h3>Tipo de producto</h3>
									{
										this.props.product_types.map((item: any) => {
											return (
												<button className={ `btn-category ${ item?.id == this.props.match.params.product_type_id ? 'active' : '' }` } onClick={ () => this.props.history.replace(`/view-category/` + item?.id) }>
													{ item?.name }
												</button>
											)
										})
									}
								</div>
								<div className="container-item-filter">
									<h3>Categorías</h3>
									<div className="container-scroll">
										{
											this.props.categories.map((item: any) => {
												return (
													<button className={ `btn-gray ${ item?.id == this.props.match.params.category_id ? 'active' : '' }` } onClick={ () => this.props.history.replace(`/view-category/` + Constants.PRODUCT_TYPES.PRODUCT + '/' + item?.id) }>
														{ item?.name }
													</button>
												)
											})
										}
									</div>
								</div>
								<div className="container-item-filter">
									<h3>Filtro de precio</h3>
									{
										this.state.price_filter != null && (
											<React.Fragment>
												<RangeSlider
													step={ 1 }
													min={ this.state.since }
													max={ this.state.until }
													value={ this.state.price_filter }
													onInput={ async (e: any) => {
														await this.setState({
															price_filter: e
														});
														clearTimeout(this.timer);
														this.timer = setTimeout(async () => {
															await this.setState({
														  		page: 1,
														  		last_page: 1
														  	});
															this.load(true);
														},1000);
													} }
												/>
												<p className="price-filter-header">Precio</p>
												{/*
												// @ts-ignore */}
												<p className="price-filter">{ this.state.price_filter.map((i: any) => Globals.formatMiles(i)).join(' - ') }</p>
											</React.Fragment>
										)
									}
									<button className="btn-green">
										Aplicar
									</button>
								</div>
							</div>
						</div>
						<div className="col-md-9" style={{ paddingLeft: '0px' }}>
							<div className="container-header" style={{ backgroundImage: `url(${ HeaderImage })` }}>
								{/*{
									this.props.user == null && (
										<Link to="/login">
											<img src={ BtnHeaderImage } />
										</Link>
									)
								}*/}
							</div>
							<div id="container-products">
								{/*
								// @ts-ignore */}
								<h3 className="title-green">{ this.props.match.params.product_type_id == Constants.PRODUCT_TYPES.PRODUCT ? 'Productos' : 'Combos' }</h3>

			            		{
			            			this.state.loading ? (
			            				<div className="container-loading">
			            					<i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
			            				</div>
			            			) :  (
			            				<div className="row row-products">
			            					{
						            			this.state.data.map((item: any) => {
						            				return (
						            					<div className="col-md-3">
						            						<Link to={ `/view-product/${ item?.id }` } className="link-product">
								            					<ProductItem item={ item } />
								            				</Link>
						            					</div>
						            				)
						            			})
						            		}

						            		{
						            			this.state.data.length == 0 && (
						            				<p className="message-no-items">Lo sentimos, no se han encontrado productos con estas especificaciones</p>
						            			)
						            		}
			            				</div>
			            			)
			            		}

			            		<Pagination 
									pages={ this.state.last_page } 
									active={ this.state.page }
									onChange={ async (page: number) => {
										await this.setState({
											page: page
										});
										this.load();
									} } />
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user,
		product_types: state.product_types,
		last_products: state.last_products,
		categories: state.categories
	}
})(ViewCategory);