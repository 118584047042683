import CalendarIcon from './calendar.png';
import Search from './search.svg';
import PaperclipIcon from './paperclip.png';
import UserIcon from './user.png';
import UserAvatar from './user.svg';
import ArrowLeft from './arrow-left.png';
import LockIcon from './lock.png';
import PasswordInactive from './password-inactive.png';
import PasswordActive from './password-active.png';
import NotificationIcon from './notifications.png';
import LogoutIcon from './logout.png';
import ProfileIcon from './profile.png';
import DownloadIcon from './download.png';
import EditIcon from './edit.png';
import EditIconRed from './edit-red.png';
import AddIcon from './add.png';
import SearchIcon from './search.png';
import TrashIcon from './trash.png';
import ArrowUp from './arrow-up.png';
import ArrowBottom from './arrow-bottom.png';
import ViewIcon from './view.png';
import HashIcon from './hash.png';
import DownloadTableIcon from './download2.png';
import AddWhiteIcon from './add-white.png';
import EditIconWhite from './edit-white.png';
import ViewProviderIcon from './view-provider.png';
import LocationIcon from './location.png';
import MailIcon from './mail.png';
import PhoneIcon from './phone.png';
import TimeIcon from './time.png';
import TimeRedIcon from './time-red.png';
import PhoneRedIcon from './phone-red.png';
import MailRedIcon from './mail-red.png';
import EditCircleRed from './edit-circle-red.png';
import Switch from './switch.png';
import SwitchGreen from './switch-green.png';
import AddCircleRed from './add-circle-red.png';
import ViewUserIcon from './view-user.png';
import ArrowBottomBlack from './arrow-bottom-black.png';
import ArrowUpBlack from './arrow-up-black.png';
import CalendarWhite from './calendar-white.png';
import CommentsIcon from './comments.png';
import CommentsRedIcon from './comments-red.png';
import ShapeIcon from './shape.png';
import ColorIcon from './color.png';
import RotationIcon from './rotation.png';
import DeleteIcon from './delete.png';
import CheckRedIcon from './check-red.png';
import TimeCircleRed from './time-circle-red.png';
import CircleYellow from './circle-yellow.png';
import CircleYellowBell from './circle-yellow-bell.png';
import CircleGreen from './circle-green.png';
import CalendarRed from './calendar-red.png';
import CloseIcon from './close.png';
import HomeIcon from './home.png';
import CheckGreenIcon from './check-green.png';
import CanceledIcon from './canceled.png';
import MenuOpenIcon from './menu-open.png';
import CleanIcon from './clean.png';
import TrashWhiteIcon from './trash-white.png';
import CheckWhiteIcon from './check-white.png';
import ConfirmIcon from './confirm.png';
import StarIcon from './star.png';
import ArrowLeftBlack from './arrow-left-black.png';
import ArrowRightBlack from './arrow-right-black.png';
import PriceIcon from './price.png';
import GoogleMapsIcon from './google-maps.png';
import PrintIcon from './print.png';
import OperatorsIcon from './operators.png';
import QuoteIcon from './quote.png';
import ChangeStatusIcon from './change-status.png';
import CloseWhiteIcon from './close-white.png';
import UploadIcon from './upload.png';
import ArrowLeftWhite from './arrow-left-white.png';
import TicketIcon from './ticket.png';
import IncomeIcon from './income.png';
import ReturnsIcon from './returns.png';
import ExistenceIcon from './existence.png';
import IncomeWhiteIcon from './income-white.png';
import ReturnsWhiteIcon from './returns-white.png';
import ExistenceWhiteIcon from './existence-white.png';
import WhatsappIcon from './whatsapp.png';
import LocationWhiteIcon from './location-white.png';
import PhoneWhiteIcon from './phone-white.png';
import MailWhiteIcon from './mail-white.png';
import InstagramWhiteIcon from './instagram-white.png';
import InstagramYellowIcon from './instagram-yellow.png';
import CartWhiteIcon from './cart-white.png';
import CategoriesIcon from './categories.png';
import SearchWhiteIcon from './search-white.png';
import LikeIcon from './like.png';
import LockWhiteIcon from './lock-white.png';
import ProfileWhiteIcon from './profile-white.png';
import LogoutWhiteIcon from './logout-white.png';
import EditSquareIcon from './edit-square.png';
import CheckIcon from './check-black.png';
import BoxIcon from './box.png';
import ProductsIcon from './products.png';
import PaymentMovilIcon from './payment-movil.png';
import ZelleIcon from './zelle.png';
import PaymentMovilWhiteIcon from './payment-movil-white.png';
import ZelleWhiteIcon from './zelle-white.png';
import InstagramIcon from './instagram.png';
import ShareIcon from './share.png';
import ArrowGrayIcon from './arrow-gray.png';
import SearchGrayIcon from './search-gray.png';
import FacebookIcon from './facebook.png';
import TwitterIcon from './twitter.png';
import WhatsappFooterIcon from './whatsapp-footer.png';

// Menu
import MenuModerators from './menu/moderators.png';
import MenuServices from './menu/services.png';
import MenuOperators from './menu/operators.png';
import MenuExchange from './menu/exchange.png';
import MenuQuotes from './menu/quotes.png';
import MenuClients from './menu/clients.png';
import MenuBankAccounts from './menu/bank-accounts.png';
import MenuClientHome from './menu/client-home.png';
import MenuClientProfile from './menu/client-profile.png';
import MenuClientQuotes from './menu/client-quotes.png';
import MenuDiscount from './menu/discount.png';
import MenuShop from './menu/shop.png';

const Menu = {
	MenuOperators,
	MenuModerators,
	MenuServices,
	MenuExchange,
	MenuQuotes,
	MenuClients,
	MenuClientHome,
	MenuClientProfile,
	MenuClientQuotes,
	MenuBankAccounts,
	MenuDiscount,
	MenuShop
}

export {
	CalendarIcon,
	Search,
	ArrowLeft,
	PaperclipIcon,
	UserIcon,
	UserAvatar,
	LockIcon,
	PasswordInactive,
	PasswordActive,
	Menu,
	NotificationIcon,
	LogoutIcon,
	ProfileIcon,
	DownloadIcon,
	EditIcon,
	EditIconRed,
	AddIcon,
	SearchIcon,
	TrashIcon,
	ArrowUp,
	ArrowBottom,
	ViewIcon,
	HashIcon,
	DownloadTableIcon,
	AddWhiteIcon,
	EditIconWhite,
	ViewProviderIcon,
	PhoneIcon,
	MailIcon,
	LocationIcon,
	TimeIcon,
	TimeRedIcon,
	PhoneRedIcon,
	MailRedIcon,
	EditCircleRed,
	Switch,
	SwitchGreen,
	AddCircleRed,
	ViewUserIcon,
	ArrowBottomBlack,
	ArrowUpBlack,
	CalendarWhite,
	CommentsIcon,
	CommentsRedIcon,
	ShapeIcon,
	DeleteIcon,
	RotationIcon,
	ColorIcon,
	CheckRedIcon,
	TimeCircleRed,
	CircleYellow,
	CircleYellowBell,
	CircleGreen,
	CalendarRed,
	CloseIcon,
	HomeIcon,
	CheckGreenIcon,
	CanceledIcon,
	MenuOpenIcon,
	CleanIcon,
	TrashWhiteIcon,
	CheckWhiteIcon,
	ConfirmIcon,
	StarIcon,
	ArrowLeftBlack,
	ArrowRightBlack,
	PriceIcon,
	GoogleMapsIcon,
	OperatorsIcon,
	PrintIcon,
	QuoteIcon,
	CloseWhiteIcon,
	ChangeStatusIcon,
	UploadIcon,
	ArrowLeftWhite,
	TicketIcon,
	IncomeIcon,
	ReturnsIcon,
	ExistenceIcon,
	IncomeWhiteIcon,
	ReturnsWhiteIcon,
	ExistenceWhiteIcon,
	WhatsappIcon,
	LocationWhiteIcon,
	PhoneWhiteIcon,
	MailWhiteIcon,
	InstagramWhiteIcon,
	InstagramYellowIcon,
	CartWhiteIcon,
	CategoriesIcon,
	SearchWhiteIcon,
	LikeIcon,
	LockWhiteIcon,
	ProfileWhiteIcon,
	LogoutWhiteIcon,
	EditSquareIcon,
	CheckIcon,
	BoxIcon,
	ProductsIcon,
	PaymentMovilIcon,
	ZelleIcon,
	PaymentMovilWhiteIcon,
	ZelleWhiteIcon,
	InstagramIcon,
	ShareIcon,
	ArrowGrayIcon,
	SearchGrayIcon,
	TwitterIcon,
	FacebookIcon,
	WhatsappFooterIcon
}