import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import Layout from 'screens/public/layout/layout';
import { Constants, Globals, Currency, Cart } from 'utils';
import { WhiteImage } from 'assets/img';
import NavigationBar from '../layout/navigation-bar';
import { AddIcon, EditIcon, TrashIcon, EditSquareIcon, CheckIcon, CheckWhiteIcon, CloseWhiteIcon, PaymentMovilIcon, ZelleIcon, PaymentMovilWhiteIcon, ZelleWhiteIcon } from 'assets/icons';
import { PaymentService, ProductService, ProfileService } from 'services';
import { Input, Select, Textarea, DatePicker, PaperClip, Modal } from 'components';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const STEPS = {
	DATA: 1,
	DELIVERY: 2,
	ADDITIONAL: 3,
	PAYMENT: 4,
	SUMMARY: 5
}

class ViewPayment extends React.Component<Props> {

	state = {
		loading: true,
		step: STEPS.DATA,
		iva: 0,
		delivery_types: [],
		document_types: [],
		states: [],
		cities: [],
		payment_methods: [],
		banks: [],
		user_delivery: null,
		form: {
			delivery_type_id: Constants.DELIVERY_TYPES.PICKUP,
			name: '',
			lastname: '',
			email: '',
			phone: '',
			document_type_id: Constants.DOCUMENT_TYPES.V,
			document: '',
			gender: '',
			state_id: '',
			city_id: '',
			address: '',
			postal_code: '',
			sector: '',
			reference: '',
			saving_name: '',
			saving_lastname: '',
			saving_email: '',
			saving_phone: '',
			saving_document_type_id: Constants.DOCUMENT_TYPES.V,
			saving_document: '',
			saving_gender: '',
			saving_state_id: '',
			saving_city_id: '',
			saving_address: '',
			saving_postal_code: '',
			saving_sector: '',
			saving_reference: '',
			address_selected: null,
			payment_method_id: '',
			delivery_name: '',
			delivery_lastname: '',
			delivery_email: '',
			delivery_phone: '',
			delivery_address: '',
			delivery_state_id: '',
			delivery_city_id: '',
			delivery_sector: '',
			delivery_postal_code: '',
			delivery_reference: '',
			delivery_address_selected: null,
			save_delivery: false,
			delivery_document_type_id: Constants.DOCUMENT_TYPES.V,
			delivery_document: '',
			delivery_same: true,
			payment_bank_id: '',
			payment_phone: '',
			payment_date: '',
			payment_reference: '',
			payment_email: '',
			payment_photo: ''
		},
		splendor_addresses: [],
		address_index: null,
		add_address: false
	}

	componentDidMount() {
		if (this.props.cart.length == 0) {
			return this.props.history.replace('/view-cart');
		}

		this.loadCart();
		this.load();
		this.loadAddresses();
	}

	loadCart = async () => {
		const res: any = await ProductService.public.updateCart({
			product_ids: JSON.stringify(this.props.cart?.map((i: any) => i.product_id)),
			withoutLoading: true
		});
		if (res.products) {
			let cart = [...this.props.cart];
			res.products.forEach((item: any) => {
				const index = cart.findIndex((i: any) => i.product_id == item?.id);
				if (index != -1) {
					if (item?.deleted_at == null && item?.stock > 0) {
						cart[index].product = item;
					}
					else {
						cart.splice(index,1);
					}
				}
			});
			this.props.dispatch({
				type: 'SET_CART',
				payload: cart
			});
		}
	}

	loadAddresses = async () => {
		const res: any = await ProfileService.getAddresses({
			user_id: this.props.user?.id,
			withoutLoading: true
		});
		this.setState({
			splendor_addresses: res.splendor_addresses || []
		});
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await PaymentService.public.get({
			user_id: this.props.user?.id
		});
		this.setState({
			iva: res.iva,
			delivery_types: res.delivery_types,
			states: res.states,
			document_types: res.document_types,
			cities: res.cities,
			payment_methods: res.payment_methods,
			banks: res.banks,
			user_delivery: res.user_delivery
		});
	}

	save = async () => {
		this.props.cart.forEach((i: any) => {
			if (i?.product?.stock < i.quantity) {
				Globals.showError(`Lo sentimos, el producto ${ i?.product?.name } no posee suficiente existencia`);
				return;
			}
		});

		const res: any = await PaymentService.public.save({
			hasFile: true,
			user_id: this.props.user?.id,
			details: JSON.stringify(this.props.cart.map((i: any) => {
				return {
					product_id: i.product_id,
					quantity: i.quantity
				}
			})),
			...this.state.form,
			// @ts-ignore
			payment_photo: this.state.form.payment_photo?.value,
			payment_date: this.state.form.payment_date && moment(this.state.form.payment_date).format('YYYY-MM-DD')
		});
		Cart.destroy();
		this.props.history.replace('/?success=1&delivery_type_id=' + this.state.form.delivery_type_id);
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	validateAddress = (callback = () => {}) => {
		const {
			saving_name,
			saving_lastname,
			saving_email,
			saving_phone,
			saving_document_type_id,
			saving_document,
			saving_gender,
			saving_state_id,
			saving_city_id,
			saving_sector,
			saving_postal_code,
			saving_address,
			saving_reference
		} = this.state.form;
		let _name = '';

		if (!_name && !saving_name) _name = 'Nombre';
		if (!_name && !saving_lastname) _name = 'Apellido';
		if (!_name && !saving_email) _name = 'Email';
		if (!_name && !saving_phone) _name = 'Teléfono';
		if (!_name && !saving_document) _name = 'Cédula/RIF';
		if (!_name && !saving_document_type_id) _name = 'Cédula/RIF';
		if (!_name && !saving_gender) _name = 'Sexo';
		if (!_name && !saving_state_id) _name = 'Estado';
		if (!_name && !saving_city_id) _name = 'Ciudad';
		if (!_name && !saving_sector) _name = 'Sector';
		if (!_name && !saving_postal_code) _name = 'Código postal';
		if (!_name && !saving_address) _name = 'Dirección';
		if (!_name && !saving_reference) _name = 'Referencia';

		if (_name) {
			Globals.showError(`El campo ${ _name } es requerido`);
			return;
		}

		callback();
	}

	validate = async (callback = () => {}) => {
		let _name = '';
		switch(this.state.step) {
			case STEPS.DATA:
				if (this.state.form.address_selected == null) {
					Globals.showError(`Debe seleccionar una dirección para continuar`);
					return;
				}

				const _address: any = this.state.splendor_addresses.find((item: any) => item.id == this.state.form.address_selected)

				await this.setState({
					form: {
						...this.state.form,
						name: _address?.name || '',
						lastname: _address?.lastname || '',
						email: _address?.email || '',
						phone: _address?.phone || '',
						document: _address?.document || '',
						gender: _address?.gender || '',
						state_id: _address?.state_id || '',
						city_id: _address?.city_id || '',
						sector: _address?.sector || '',
						postal_code: _address?.postal_code || '',
						address: _address?.address || '',
					}
				});

				const {
					name,
					lastname,
					email,
					phone,
					document,
					gender,
					state_id,
					city_id,
					sector,
					postal_code,
					address,
				} = this.state.form;
				_name = '';

				if (!_name && !name) _name = 'Nombre';
				if (!_name && !lastname) _name = 'Apellido';
				if (!_name && !email) _name = 'Email';
				if (!_name && !phone) _name = 'Teléfono';
				if (!_name && !document) _name = 'Cédula/RIF';
				if (!_name && !gender) _name = 'Sexo';
				if (!_name && !state_id) _name = 'Estado';
				if (!_name && !city_id) _name = 'Ciudad';
				if (!_name && !sector) _name = 'Sector';
				if (!_name && !postal_code) _name = 'Código postal';
				if (!_name && !address) _name = 'Dirección';

				if (_name) {
					Globals.showError(`El campo ${ _name } es requerido`);
					return;
				}
			break;

			case STEPS.ADDITIONAL:
				if (this.state.form.delivery_type_id == Constants.DELIVERY_TYPES.DELIVERY) {
					if (this.state.form.delivery_address_selected == null) {
						Globals.showError(`Debe seleccionar una dirección para continuar`);
						return;
					}

					const _address: any = this.state.splendor_addresses.find((item: any) => item.id == this.state.form.delivery_address_selected)

					await this.setState({
						form: {
							...this.state.form,
							delivery_name: _address?.name || '',
							delivery_email: _address?.email || '',
							delivery_lastname: _address?.lastname || '',
							delivery_phone: _address?.phone || '',
							delivery_document: _address?.document || '',
							delivery_address: _address?.address || '',
							delivery_postal_code: _address?.postal_code || '',
							delivery_state_id: _address?.state_id || '',
							delivery_city_id: _address?.city_id || '',
							delivery_sector: _address?.sector || '',
							delivery_reference: _address?.reference || ''
						}
					});
				}

				const {
					delivery_name,
					delivery_email,
					delivery_lastname,
					delivery_phone,
					delivery_document,
					delivery_address,
					delivery_postal_code,
					delivery_state_id,
					delivery_city_id,
					delivery_sector,
					delivery_reference
				} = this.state.form;
				_name = '';
				if (!_name && !delivery_name) _name = 'Nombre';
				if (!_name && !delivery_lastname) _name = 'Apellido';
				if (!_name && !delivery_email) _name = 'Email';
				if (!_name && !delivery_phone) _name = 'Teléfono';

				if (this.state.form.delivery_type_id == Constants.DELIVERY_TYPES.PICKUP) {
					if (!_name && !delivery_document) _name = 'Cédula/RIF';
				}
				else if (this.state.form.delivery_type_id == Constants.DELIVERY_TYPES.DELIVERY) {
					if (!_name && !delivery_address) _name = 'Dirección';
					if (!_name && !delivery_state_id) _name = 'Estado';
					if (!_name && !delivery_city_id) _name = 'Ciudad';
					if (!_name && !delivery_sector) _name = 'Sector';
					if (!_name && !delivery_postal_code) _name = 'Código Postal';
				}

				if (_name) {
					Globals.showError(`El campo ${ _name } es requerido`);
					return;
				}
			break;

			case STEPS.PAYMENT:
				const {
					payment_photo,
					payment_reference,
					payment_bank_id,
					payment_date,
					payment_phone,
					payment_email
				} = this.state.form;
				_name = '';
				
				if (
					// @ts-ignore
					this.state.form.payment_method_id == Constants.PAYMENT_METHODS.MOVIL) {
					if (!_name && !payment_bank_id) _name = 'Banco emisor';
					if (!_name && !payment_date) _name = 'Fecha';
					if (!_name && !payment_phone) _name = 'Teléfono';
					if (!_name && !payment_reference) _name = 'Número de Referencia';
				}
				else if (
					// @ts-ignore
					this.state.form.payment_method_id == Constants.PAYMENT_METHODS.ZELLE) {
					if (!_name && !payment_email) _name = 'Correo emisor';
					if (!_name && !payment_date) _name = 'Fecha';
					if (!_name && !payment_reference) _name = 'Número de confirmación';
					if (!_name && !payment_photo) _name = 'Captura de pantalla';
				}

				if (_name) {
					Globals.showError(`El campo ${ _name } es requerido`);
					return;
				}
			break;
		}

		callback();
	}

	setData = () => {
		if (this.state.form.delivery_type_id == Constants.DELIVERY_TYPES.DELIVERY) {
			if (this.state.user_delivery) {
				let user_delivery: any = this.state.user_delivery;
				this.setState({
					form: {
						...this.state.form,
						delivery_name: user_delivery?.name || '',
						delivery_lastname: user_delivery?.lastname || '',
						delivery_email: user_delivery?.email || '',
						delivery_phone: user_delivery?.phone || '',
						delivery_address: user_delivery?.address || '',
						delivery_state_id: user_delivery?.state_id || '',
						delivery_city_id: user_delivery?.city_id || '',
						delivery_sector: user_delivery?.sector || '',
						delivery_postal_code: user_delivery?.postal_code || '',
						delivery_reference: user_delivery?.reference || '',
						save_delivery: true
					}
				});
			}
		}
		else {
			this.setState({
				form: {
					...this.state.form,
					delivery_name: '',
					delivery_lastname: '',
					delivery_email: '',
					delivery_phone: '',
					delivery_address: '',
					delivery_state_id: '',
					delivery_city_id: '',
					delivery_sector: '',
					delivery_postal_code: '',
					delivery_reference: '',
					save_delivery: false
				}
			});
		}
	}

	setAdditionalData = () => {
		if (this.state.form.delivery_same) {
			if (!this.state.form.delivery_name) {
				this.setState({
					form: {
						...this.state.form,
						delivery_name: this.state.form.name || '',
						delivery_lastname: this.state.form.lastname || '',
						delivery_email: this.state.form.email || '',
						delivery_phone: this.state.form.phone || '',
						delivery_document_type_id: this.state.form.delivery_type_id || Constants.DOCUMENT_TYPES.V,
						delivery_document: this.state.form.document || ''
					}
				});
			}
		}
		else {
			this.setState({
				form: {
					...this.state.form,
					delivery_name: '',
					delivery_lastname: '',
					delivery_email: '',
					delivery_phone: '',
					delivery_document_type_id: Constants.DOCUMENT_TYPES.V,
					delivery_document: ''
				}
			});
		}
	}

	saveAddresses = async () => {
		const params = {
			name: this.state.form.saving_name,
			lastname: this.state.form.saving_lastname,
			email: this.state.form.saving_email,
			phone: this.state.form.saving_phone,
			document: this.state.form.saving_document,
			gender: this.state.form.saving_gender,
			state_id: this.state.form.saving_state_id || '',
			city_id: this.state.form.saving_city_id || '',
			sector: this.state.form.saving_sector,
			postal_code: this.state.form.saving_postal_code,
			address: this.state.form.saving_address,
			reference: this.state.form.saving_reference,
			document_type_id: this.state.form.saving_document_type_id || ''
		}
		let addresses: any = [...this.state.splendor_addresses];
		if (this.state.address_index !== null) {
			// @ts-ignore
			addresses[this.state.address_index] = params;
		}
		else {
			addresses.push(params);
		}
		await ProfileService.updateAddress({
			user_id: this.props.user?.id,
			addresses: JSON.stringify(addresses),
			withoutLoading: true
		});
		this.setState({
			address_index: null,
			add_address: false,
			form: {
				...this.state.form,
				saving_name: '',
				saving_lastname: '',
				saving_email: '',
				saving_phone: '',
				saving_document_type_id: Constants.DOCUMENT_TYPES.V,
				saving_document: '',
				saving_gender: '',
				saving_state_id: '',
				saving_city_id: '',
				saving_address: '',
				saving_postal_code: '',
				saving_sector: '',
				saving_reference: ''
			}
		},() => this.loadAddresses());
	}

	editAddress = (e: any, index: number) => {
		e.preventDefault();
		let addresses: any = [...this.state.splendor_addresses];
		const address = addresses[index];
		if (address) {
			this.setState({
				address_index: index,
				add_address: true,
				form: {
					...this.state.form,
					saving_name: address?.name || '',
					saving_lastname: address?.lastname || '',
					saving_email: address?.email || '',
					saving_phone: address?.phone || '',
					saving_document_type_id: address?.document_type_id || Constants.DOCUMENT_TYPES.V,
					saving_document: address?.document || '',
					saving_gender: address?.gender || '',
					saving_state_id: address?.state_id || '',
					saving_city_id: address?.city_id || '',
					saving_address: address?.address || '',
					saving_postal_code: address?.postal_code || '',
					saving_sector: address?.sector || '',
					saving_reference: address?.reference || ''
				}
			});
		}
	}

	onCloseAddress = () => {
		this.setState({
			add_address: false,
			address_index: null,
			form: {
				...this.state.form,
				saving_name: '',
				saving_lastname: '',
				saving_email: '',
				saving_phone: '',
				saving_document_type_id: Constants.DOCUMENT_TYPES.V,
				saving_document: '',
				saving_gender: '',
				saving_state_id: '',
				saving_city_id: '',
				saving_address: '',
				saving_postal_code: '',
				saving_sector: '',
				saving_reference: ''
			}
		});
	}

	deleteAddress = (e: any, index: number) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar la dirección guardada?',async () => {
			let addresses = [...this.state.splendor_addresses];
			addresses.splice(index,1);
			await ProfileService.updateAddress({
				user_id: this.props.user?.id,
				addresses: JSON.stringify(addresses),
				withoutLoading: true
			});
			this.loadAddresses();
		});
	}

	render() {
		const total = this.props.cart.map((i: any) => i.product?.price * i.quantity).reduce((a: number, b: number) => a + b,0);
		const iva = total * (this.state.iva / 100);
		const payment_movil: any = this.state.payment_methods.find((i: any) => i.id == Constants.PAYMENT_METHODS.MOVIL);
		const payment_zelle: any = this.state.payment_methods.find((i: any) => i.id == Constants.PAYMENT_METHODS.ZELLE);
		const document_type: any = this.state.document_types.find((i: any) => i.id == this.state.form.document_type_id);
		const delivery_document_type: any = this.state.document_types.find((i: any) => i.id == this.state.form.delivery_document_type_id);
		const payment_method: any = this.state.payment_methods.find((i: any) => i.id == this.state.form.payment_method_id);
		const delivery_type: any = this.state.delivery_types.find((i: any) => i.id == this.state.form.delivery_type_id);
		const payment_bank: any = this.state.banks.find((i: any) => i.id == this.state.form.payment_bank_id);

		return (
			<Layout>
				<div id="public-view-payment">
					<NavigationBar
						items={[
							{ label: 'Inicio', url: '/' },
							{ label: 'Facturación' }
						]}
					/>

					<Modal
			          className="modal-add-address"
			          visible={ this.state.add_address }
			          title=""
			          onClose={ () => this.onCloseAddress() }
			        >
			        	<div id="modal-add-address">
				            <h4>Datos personales</h4>
							<div className="row">
								<div className="col-md-6">
									<Input
										color="white"
										value={ this.state.form.saving_name }
										name="saving_name" 
										label="Nombre"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<Input
										color="white"
										value={ this.state.form.saving_lastname }
										name="saving_lastname" 
										label="Apellido"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<Input
										color="white"
										value={ this.state.form.saving_email }
										name="saving_email" 
										label="Correo"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<Input
										type="number"
										color="white"
										value={ this.state.form.saving_phone }
										name="saving_phone" 
										label="Teléfono"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<label>Cédula / RIF</label>
									<div className="container-document">
										<Select
											placeholder="Tipo de Documento"
											name="saving_document_type_id"
											onChange={ (e: any) => {
												this.setState({
													form: {
														...this.state.form,
														saving_document_type_id: parseInt(e.target.value)
													}
												});
											} }
											value={ this.state.form.document_type_id }
											options={ this.state.document_types.map((i: any) => {
												return {
													value: i.id,
													label: i.name
												}
											}) } />
										<Input
											type="number"
											color="white"
											value={ this.state.form.saving_document }
											name="saving_document" 
											onChange={ this.change } />
									</div>
								</div>
								<div className="col-md-6">
									<Select
										label="Sexo"
										name="saving_gender"
										onChange={ (e: any) => {
											this.setState({
												form: {
													...this.state.form,
													saving_gender: e.target.value
												}
											});
										} }
										value={ this.state.form.saving_gender }
										options={ [
											{ value: 'M', label: 'Masculino' },
											{ value: 'F', label: 'Femenino' },
										] } />
								</div>
							</div>
							<h4>Datos de ubicación</h4>
							<div className="row">
								<div className="col-md-6">
									<Select
										label="Estado"
										name="saving_state_id"
										onChange={ (e: any) => {
											this.setState({
												form: {
													...this.state.form,
													saving_state_id: e.target.value,
													saving_city_id: ''
												}
											});
										} }
										value={ this.state.form.saving_state_id }
										options={ this.state.states.map((i: any) => {
											return {
												value: i.id,
												label: i.name
											}
										}) } />
								</div>
								<div className="col-md-6">
									<Select
										label="Ciudad"
										name="saving_city_id"
										onChange={ (e: any) => {
											this.setState({
												form: {
													...this.state.form,
													saving_city_id: e.target.value
												}
											});
										} }
										value={ this.state.form.saving_city_id }
										options={ this.state.form.saving_state_id ? this.state.cities.filter((i: any) => i.state_id == this.state.form.saving_state_id).map((i: any) => {
											return {
												value: i.id,
												label: i.name
											}
										}) : [] } />
								</div>
								<div className="col-md-6">
									<Input
										color="white"
										value={ this.state.form.saving_sector }
										name="saving_sector" 
										label="Sector"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<Input
										color="white"
										value={ this.state.form.saving_postal_code }
										name="saving_postal_code" 
										label="Código postal"
										onChange={ this.change } />
								</div>
								<div className="col-12">
									<Textarea
										rows={ 4 }
										label="Dirección"
										name="saving_address"
										onChange={ this.change }
					                    value={ this.state.form.saving_address } />
								</div>
								<div className="col-12">
									<Textarea
										rows={ 4 }
										label="Referencia"
										name="saving_reference"
										onChange={ this.change }
					                    value={ this.state.form.saving_reference } />
								</div>
							</div>
							<button className="btn-green" onClick={ () => {
								this.validateAddress(() => {
									this.saveAddresses();
								});
							} }>
								Guardar
							</button>
						</div>
			      	</Modal>

					<div className="container-payment">
						<h3>Facturación</h3>
						<div className="row">
							<div className="col-md-8">
								<div className="item-number">
									<div>
										<span>1</span>
										<p>Confirmar datos de facturación</p>
									</div>
									{
										this.state.step > STEPS.DATA && (
											<img src={ EditSquareIcon } onClick={ () => {
												if (this.state.step > STEPS.DATA) {
													this.setState({
														step: STEPS.DATA
													});
												}
											} } />
										)
									}
								</div>
								{
									this.state.step == STEPS.DATA && (
										<div className="container-data">
											<div className="text-right">
												<a href="#" className="container-add-address" onClick={ (e: any) => {
													e.preventDefault();
													this.setState({
														add_address: true,
														address_index: null
													});
												} }>
													<p>Agregar dirección</p>
													<img src={ AddIcon } />
												</a>
											</div>
											{
												this.state.splendor_addresses.map((item: any, index: number) => {
													return (
														<div className="splendor-address-container">
															<div className="container-data">
																<a href="#" onClick={ (e: any) => this.editAddress(e,index) }>
																	<img src={ EditIcon } />
																</a>
																<a href="#" onClick={ (e: any) => this.deleteAddress(e,index) }>
																	<img src={ TrashIcon } />
																</a>
															</div>
															<div className="container-data" style={{ flex: 1, display: 'flex' }}>
																<p>{ item.name + ' ' + item.lastname }</p>
																<p style={{ flex: 1, marginLeft: '10px' }}>{ item.address }</p>
															</div>
															<div className="container-data">
																<div className="check" onClick={ () => this.setState({
																	form: {
																		...this.state.form,
																		address_selected: this.state.form.address_selected == item.id ? null : item.id
																	}
																}) }>
																	{
																		item.id == this.state.form.address_selected && (
																			<img src={ CheckIcon } />
																		)
																	}
																</div>
															</div>
														</div>
													)
												})
											}
											{
												this.state.splendor_addresses.length == 0 && (
													<p className="no-addresses">No se han agregado direcciones</p>
												)
											}
											<button className="btn-green" onClick={ () => {
												this.validate(() => {
													this.setState({
														step: STEPS.DELIVERY
													});
												});
											} }>
												Siguiente
											</button>
										</div>
									)
								}
								<div className="item-number">
									<div>
										<span>2</span>
										<p>Método de entrega</p>
									</div>
									{
										this.state.step > STEPS.DELIVERY && (
											<img src={ EditSquareIcon } onClick={ () => {
												if (this.state.step > STEPS.DELIVERY) {
													this.setState({
														step: STEPS.DELIVERY
													});
												}
											} } />
										)
									}
								</div>
								{
									this.state.step == STEPS.DELIVERY && (
										<div className="container-data">
											{
												this.state.delivery_types.map((item: any) => {
													return (
														<button className={ `btn-delivery ${ item?.id == this.state.form.delivery_type_id ? 'active' : '' }` } onClick={ () => {
															this.setState({
																form: {
																	...this.state.form,
																	delivery_type_id: item?.id
																}
															},() => this.setData());
														} }>
															<div className="item-check">
																{
																	item?.id == this.state.form.delivery_type_id && (
																		<img src={ CheckIcon } />
																	)
																}
															</div>
															<div>
																<p className="title-delivery">{ item?.name }</p>
																<p>{ ReactHtmlParser(item?.description || this.props.contact_info?.address) }</p>
																<p>{ item?.condition }</p>
															</div>															
														</button>
													)
												})
											}
											<button className="btn-green" onClick={ () => {
												this.validate(() => {
													this.setState({
														step: STEPS.ADDITIONAL
													},() => this.setAdditionalData());
												});
											} }>
												Siguiente
											</button>
										</div>
									)
								}
								<div className="item-number">
									<div>
										<span>3</span>
										<p>Datos adicionales</p>
									</div>
									{
										this.state.step > STEPS.ADDITIONAL && (
											<img src={ EditSquareIcon } onClick={ () => {
												if (this.state.step > STEPS.ADDITIONAL) {
													this.setState({
														step: STEPS.ADDITIONAL
													});
												}
											} } />
										)
									}
								</div>
								{
									this.state.step == STEPS.ADDITIONAL && (
										<div className="container-data">
											{
												// @ts-ignore
												this.state.form.delivery_type_id == Constants.DELIVERY_TYPES.PICKUP && (
													<React.Fragment>
														<h4>Persona que retira</h4>

														<div className="row">
															<div className="col-md-6">
																<p className="container-check" onClick={ () => {
																	this.setState({
																		form: {
																			...this.state.form,
																			delivery_same: true
																		}
																	},() => this.setAdditionalData());
																} }>
																	<div className={ `container-circle-green ${ this.state.form.delivery_same ? 'active' : '' }` }>
																		{
																			this.state.form.delivery_same && (
																				<div className="circle-green"></div>
																			)
																		}
																	</div>
																	Retirar personalmente
																</p>
															</div>
															<div className="col-md-6">
																<p className="container-check" onClick={ () => {
																	this.setState({
																		form: {
																			...this.state.form,
																			delivery_same: false
																		}
																	},() => this.setAdditionalData());
																} }>
																	<div className={ `container-circle-green ${ !this.state.form.delivery_same ? 'active' : '' }` }>
																		{
																			!this.state.form.delivery_same && (
																				<div className="circle-green"></div>
																			)
																		}
																	</div>
																	Asignar otra persona
																</p>
															</div>
														</div>

														<div className="row">
															<div className="col-md-6">
																<Input
																	color="white"
																	value={ this.state.form.delivery_name }
																	name="delivery_name" 
																	label="Nombre"
																	onChange={ this.change } />
															</div>
															<div className="col-md-6">
																<Input
																	color="white"
																	value={ this.state.form.delivery_lastname }
																	name="delivery_lastname" 
																	label="Apellido"
																	onChange={ this.change } />
															</div>
															<div className="col-md-6">
																<Input
																	color="white"
																	value={ this.state.form.delivery_email }
																	name="delivery_email" 
																	label="Correo"
																	onChange={ this.change } />
															</div>
															<div className="col-md-6">
																<Input
																	type="number"
																	color="white"
																	value={ this.state.form.delivery_phone }
																	name="delivery_phone" 
																	label="Teléfono"
																	onChange={ this.change } />
															</div>
															<div className="col-md-6">
																<label>Cédula / RIF</label>
																<div className="container-document">
																	<Select
																		placeholder="Tipo de Documento"
																		name="delivery_document_type_id"
																		onChange={ (e: any) => {
																			this.setState({
																				form: {
																					...this.state.form,
																					delivery_document_type_id: parseInt(e.target.value)
																				}
																			});
																		} }
																		value={ this.state.form.delivery_document_type_id }
																		options={ this.state.document_types.map((i: any) => {
																			return {
																				value: i.id,
																				label: i.name
																			}
																		}) } />
																	<Input
																		type="number"
																		color="white"
																		value={ this.state.form.delivery_document }
																		name="delivery_document" 
																		onChange={ this.change } />
																</div>
															</div>
														</div>
													</React.Fragment>
												)
											}
											{
												// @ts-ignore
												this.state.form.delivery_type_id == Constants.DELIVERY_TYPES.DELIVERY && (
													<React.Fragment>
														<h4>Ingresar dirección de envío</h4>
														
														<div className="text-right">
															<a href="#" className="container-add-address" onClick={ (e: any) => {
																e.preventDefault();
																this.setState({
																	add_address: true,
																	address_index: null
																});
															} }>
																<p>Agregar dirección</p>
																<img src={ AddIcon } />
															</a>
														</div>
														{
															this.state.splendor_addresses.map((item: any, index: number) => {
																return (
																	<div className="splendor-address-container">
																		<div className="container-data">
																			<a href="#" onClick={ (e: any) => this.editAddress(e,index) }>
																				<img src={ EditIcon } />
																			</a>
																			<a href="#" onClick={ (e: any) => this.deleteAddress(e,index) }>
																				<img src={ TrashIcon } />
																			</a>
																		</div>
																		<div className="container-data" style={{ flex: 1, display: 'flex' }}>
																			<p>{ item.name + ' ' + item.lastname }</p>
																			<p style={{ flex: 1, marginLeft: '10px' }}>{ item.address }</p>
																		</div>
																		<div className="container-data">
																			<div className="check" onClick={ () => this.setState({
																				form: {
																					...this.state.form,
																					delivery_address_selected: this.state.form.delivery_address_selected == item.id ? null : item.id
																				}
																			}) }>
																				{
																					item.id == this.state.form.delivery_address_selected && (
																						<img src={ CheckIcon } />
																					)
																				}
																			</div>
																		</div>
																	</div>
																)
															})
														}
														{
															this.state.splendor_addresses.length == 0 && (
																<p className="no-addresses">No se han agregado direcciones</p>
															)
														}
													</React.Fragment>
												)
											}
											<button className="btn-green" onClick={ () => {
												this.validate(() => {
													this.setState({
														step: STEPS.PAYMENT
													});
												});
											} }>
												Siguiente
											</button>
										</div>
									)
								}
								<div className="item-number">
									<div>
										<span>4</span>
										<p>Forma de pago</p>
									</div>
									{
										this.state.step > STEPS.PAYMENT && (
											<img src={ EditSquareIcon } onClick={ () => {
												if (this.state.step > STEPS.PAYMENT) {
													this.setState({
														step: STEPS.PAYMENT
													});
												}
											} } />
										)
									}
								</div>
								{
									this.state.step == STEPS.PAYMENT && (
										<div className="container-data">
											<div className="row">
												{
													this.state.payment_methods.map((item: any) => {
														return (
															<div className="col-md-6">
																<button className={ `btn-payment-method ${ this.state.form.payment_method_id == item?.id ? 'active' : '' }` } onClick={ () => {
																	this.setState({
																		form: {
																			...this.state.form,
																			payment_method_id: item?.id
																		}
																	});
																} }>
																	<img src={ item?.id == Constants.PAYMENT_METHODS.ZELLE ? (this.state.form.payment_method_id == item?.id ? ZelleWhiteIcon : ZelleIcon) : (this.state.form.payment_method_id == item?.id ? PaymentMovilWhiteIcon : PaymentMovilIcon) } />
																	{ item?.name }
																</button>
															</div>
														)
													})
												}
											</div>
											{
												// @ts-ignore
												this.state.form.payment_method_id == Constants.PAYMENT_METHODS.MOVIL && (
													<React.Fragment>
														<div className="data-to-payment">
															<p className="bold">Datos para el pago</p>
															<p>{ payment_movil?.bank?.name } - { payment_movil?.bank?.code }</p>
															<p>{ payment_movil?.document_type?.name + payment_movil?.document }</p>
															<p>{ payment_movil?.phone }</p>
														</div>
														<p className="title-data-payment">Confirmar pago</p>
														<div className="row">
															<div className="col-12">
																<Select
																	label="Banco emisor"
																	name="payment_bank_id"
																	onChange={ (e: any) => {
																		this.setState({
																			form: {
																				...this.state.form,
																				payment_bank_id: e.target.value
																			}
																		});
																	} }
																	placeholder="Seleccione"
																	value={ this.state.form.payment_bank_id }
																	options={ this.state.banks.map((i: any) => {
																		return {
																			value: i.id,
																			label: i.name
																		}
																	}) } />
															</div>
															<div className="col-md-6">
																<DatePicker
																	label="Fecha"
																	onChange={ (text: string) => {
																		this.change({
																			target: {
																				value: text,
																				name: 'payment_date'
																			}
																		});
																	} }
																	maxDate={ moment().toDate() }
																	value={ this.state.form.payment_date }
																/>
															</div>
															<div className="col-md-6">
																<Input
																	type="number"
																	color="white"
																	value={ this.state.form.payment_phone }
																	name="payment_phone" 
																	label="Teléfono"
																	onChange={ this.change } />
															</div>
															<div className="col-12">
																<Input
																	color="white"
																	className="reference"
																	value={ this.state.form.payment_reference }
																	name="payment_reference" 
																	placeholder="Últimos 4 dígitos de referencia"
																	onChange={ this.change } />
															</div>
														</div>
													</React.Fragment>
												)
											}
											{
												// @ts-ignore
												this.state.form.payment_method_id == Constants.PAYMENT_METHODS.ZELLE && (
													<React.Fragment>
														<div className="data-to-payment">
															<p className="bold">Datos para el pago</p>
															<p>{ payment_zelle?.email }</p>
														</div>
														<p className="title-data-payment">Confirmar pago</p>
														<div className="row">
															<div className="col-12">
																<Input
																	color="white"
																	value={ this.state.form.payment_email }
																	name="payment_email" 
																	label="Correo emisor"
																	onChange={ this.change } />
															</div>
															<div className="col-md-6">
																<DatePicker
																	label="Fecha"
																	onChange={ (text: string) => {
																		this.change({
																			target: {
																				value: text,
																				name: 'payment_date'
																			}
																		});
																	} }
																	maxDate={ moment().toDate() }
																	value={ this.state.form.payment_date }
																/>
															</div>
															<div className="col-md-6">
																<Input
																	color="white"
																	value={ this.state.form.payment_reference }
																	name="payment_reference" 
																	label="Número de confirmación"
																	onChange={ this.change } />
															</div>
														</div>
														<div className="container-photo">
															{
																this.state.form.payment_photo && (
																	<p className="title-photo">1 archivo seleccionado</p>
																)
															}
															<div className="container-padding">
																{
																	!this.state.form.payment_photo && (
																		<PaperClip
																			value={ null }
																			successText={ `` }
																			pendingText="Adjunta tu captura de pantalla"
																			onChange={ (e: any) => {
																				this.setState({
																					form: {
																						...this.state.form,
																						payment_photo: e.target
																					}
																				})
																			} } />
																	)
																}
																{
																	this.state.form.payment_photo && (
																		<div className="photo">
																			<div className="container-close-photo" onClick={ () => {
																				this.setState({
																					form: {
																						...this.state.form,
																						payment_photo: ''
																					}
																				});
																			} }>
																				<img src={ CloseWhiteIcon } />
																			</div>
																			{/*
																			// @ts-ignore */}
																			<img src={ this.state.form.payment_photo?.dataURL } />
																		</div>
																	)
																}
															</div>
														</div>
													</React.Fragment>
												)
											}
											{
												this.state.form.payment_method_id && (
													<button className="btn-green" onClick={ () => {
														this.validate(() => {
															this.setState({
																step: STEPS.SUMMARY
															});
														});
													} }>
														Siguiente
													</button>
												)
											}
										</div>
									)
								}
								<div className="item-number">
									<div>
										<span>5</span>
										<p>Resumen de compra</p>
									</div>
									{
										this.state.step > STEPS.SUMMARY && (
											<img src={ EditSquareIcon } onClick={ () => {
												if (this.state.step > STEPS.SUMMARY) {
													this.setState({
														step: STEPS.SUMMARY
													});
												}
											} } />
										)
									}
								</div>
								{
									this.state.step == STEPS.SUMMARY && (
										<div className="container-data container-summary">
											<h6>Resumen de compra</h6>
											<p>Cliente: { this.state.form.name + ' ' + this.state.form.lastname }</p>
											<p>Correo: { this.state.form.email }</p>
											<p>Teléfono: { this.state.form.phone }</p>
											<p>Cédula/RIF: { document_type?.name }{ this.state.form.document }</p>
											<hr />
											<p className="bold">{ delivery_type?.name }</p>
											<p>Cliente: { this.state.form.delivery_name + ' ' + this.state.form.delivery_lastname }</p>
											<p>Correo: { this.state.form.delivery_email }</p>
											<p>Teléfono: { this.state.form.delivery_phone }</p>
											{ this.state.form.delivery_type_id == Constants.DELIVERY_TYPES.PICKUP && <p>Cédula/RIF: { delivery_document_type?.name }{ this.state.form.delivery_document }</p> }
											<hr />
											<p className="bold">{ payment_method?.name }</p>
											{
												payment_method?.id == Constants.PAYMENT_METHODS.MOVIL && (
													<React.Fragment>
														<p>Banco emisor: { payment_bank?.name }</p>
														<p>Teléfono: { this.state.form.payment_phone }</p>
														<p>Nº de Referencia: { this.state.form.payment_reference }</p>
													</React.Fragment>
												)
											}
											{
												payment_method?.id == Constants.PAYMENT_METHODS.ZELLE && (
													<React.Fragment>
														<p>Correo emisor: { this.state.form.payment_email }</p>
														<p>Nº de Referencia: { this.state.form.payment_reference }</p>
													</React.Fragment>
												)
											}
											
											<div className="amount">
												<p>Monto</p>
												<p>{ Globals.formatMiles(total + iva,true,Currency.BOLIVAR,this.props.conversion?.amount) }</p>
											</div>
											<button className="btn-green" onClick={ this.save }>
												Confirmar
											</button>
										</div>
									)
								}
							</div>
							<div className="col-md-4">
								<div className="container-total">
									<h5>Resumen de pedido</h5>
									<table>
										<tr>
											<td>Items</td>
											<td className="text-right">{ this.props.cart.length }</td>
										</tr>
										<tr>
											<td>Subtotal</td>
											<td className="text-right">{ Globals.formatMiles(total,true,Currency.BOLIVAR,this.props.conversion?.amount) }</td>
										</tr>
										<tr>
											<td>IVA ({ this.state.iva }%)</td>
											<td className="text-right">{ Globals.formatMiles(iva,true,Currency.BOLIVAR,this.props.conversion?.amount) }</td>
										</tr>
										<tr>
											<td className="bold">Total</td>
											<td className="bold text-right">{ Globals.formatMiles(total + iva,true,Currency.BOLIVAR,this.props.conversion?.amount) }</td>
										</tr>
										<tr>
											<td>
												<h3>Divisas</h3>
											</td>
											<td></td>
										</tr>
										<tr>
											<td>Subtotal</td>
											<td className="text-right">{ Globals.formatMiles(total) }</td>
										</tr>
										<tr>
											<td>IVA ({ this.state.iva }%)</td>
											<td className="text-right">{ Globals.formatMiles(iva) }</td>
										</tr>
										<tr>
											<td className="bold">Total</td>
											<td className="bold text-right">{ Globals.formatMiles(total + iva) }</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user,
		cart: state.cart,
		conversion: state.conversion,
		contact_info: state.contact_info
	}
})(ViewPayment);