import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { LocationWhiteIcon, MailWhiteIcon, PhoneWhiteIcon, InstagramWhiteIcon, WhatsappIcon, InstagramIcon, TwitterIcon, FacebookIcon, WhatsappFooterIcon } from 'assets/icons';
import { Link } from 'react-router-dom';
import { LogoFooter } from 'assets/img';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

class PublicFooter extends React.Component<any> {

	render() {
		const { contact_info, product_types, last_products } = this.props;

		return (
			<div id="public-footer">
				{/*<a href={ `https://wa.me/` + contact_info?.whatsapp } target="_blank">
					<button className="btn-whatsapp-footer">
						<div className="container-whatsapp-footer">
							<img src={ WhatsappIcon } />
						</div>
					</button>
				</a>*/}
				<div className="row row-footer">
					<div className="col-md-4">
						<h3>Dirección</h3>
						<p className="address">{ ReactHtmlParser(contact_info?.address) }</p>
					</div>
					<div className="col-md-4">
						<h3>Síguenos</h3>
						<a href={ contact_info?.instagram } target="_blank">
							<img src={ InstagramWhiteIcon } className="instagram-icon" />
						</a>
						<a href={ contact_info?.facebook } target="_blank">
							<img src={ FacebookIcon } className="instagram-icon" />
						</a>
						<a href={ contact_info?.twitter } target="_blank">
							<img src={ TwitterIcon } className="instagram-icon" />
						</a>
					</div>
					<div className="col-md-4">
						<h3>Contáctanos</h3>
						<div className="container-whatsapp">
							<img src={ WhatsappFooterIcon } className="instagram-icon" />
							<div>
								<p>{ contact_info?.phone }</p>
								<p>{ contact_info?.whatsapp }</p>
							</div>
						</div>
					</div>
				</div>
				<p className="copy">{ moment().format('YYYY') } <strong>Splendor Mantenimiento, C.A.</strong> Todos los derechos reservados</p>
				<a target="_blank" className="link-limon" href="https://www.limonbyte.com">
					<p className="copy limon">Limonbyte</p>
				</a>
			</div>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user,
		contact_info: state.contact_info,
		last_products: state.last_products,
		product_types: state.product_types
	}
})(PublicFooter);