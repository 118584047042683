const code_send_at = (state = null, action: any) => {
	switch(action.type) {
		case 'SET_CODE_SEND':
            return action.payload;
            break;
        default: 
        	return state;
        	break;
	}
}

export default code_send_at;