import { combineReducers } from 'redux';
import user from './user';
import conversion from './conversion';
import contact_info from './contact-info';
import product_types from './product-types';
import last_products from './last-products';
import banners from './banners';
import cart from './cart';
import categories from './categories';
import code_send_at from './code-send-at';

export const reducer = combineReducers({
    user,
    conversion,
    contact_info,
    product_types,
    last_products,
    banners,
    cart,
    categories,
    code_send_at
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;