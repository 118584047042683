import React from 'react';
import { Input, Button, Select } from 'components';
import { connect } from 'react-redux';
import { AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router';
import { BgLogin, LoginImage, LoginImage2 } from 'assets/img';
import { ArrowLeft, LogoutIcon } from 'assets/icons';
import { Link } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const RESPONSES = {
	SUCCESS: 1,
	DENIED: 2
}

class Register extends React.Component<Props> {

	state = {
		form: {
			code: '',
			phone: ''
		},
		response: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Finalizar Registro',
				onBack: null
			}
		});

		this.check();
	}

	check = async () => {
		const res: any = await AuthService.checkUser({
          user_id: this.props.user?.id,
          withoutLoading: true
        });
        if (res.user) {
        	if (res.user?.verified == Constants.VALIDATION_STATUS.ACTIVE) {
				this.props.history.replace('/');
			}

			this.setState({
				form: {
					...this.state.form,
					phone: res.user?.phone || ''
				}
			});
        }
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async (e: any) => {
		e.preventDefault();
		this.props.history.replace('/');
	}

	getResponse = (response: number) => {
		switch(response) {
			// @ts-ignore
			case RESPONSES.SUCCESS: 
				return 'Cuenta verificada presiona finalizar para continuar';
			break;

			default:
				return 'No se pudo validar tu cuenta, verifica tu teléfono y vuelve a intentarlo';
			break;
		}
	}

	validate = async (e: any) => {
		e.preventDefault();
		if (!this.state.form.code) {
			Globals.showError("Por favor, ingrese el código de verificación");
			return;
		}
		const res: any = await AuthService.validate({
			user_id: this.props.user?.id,
			code: this.state.form.code
		});
		this.setState({
			response: res.response
		},() => {
			if (res.user) {
				this.props.dispatch({
					type: 'SET_USER',
					payload: res.user
				});
			}
		});
	}

	resend = async () => {
		await AuthService.resendCode({
			user_id: this.props.user?.id,
			phone: this.state.form.phone
		});
		Globals.showSuccess("Se ha reenviado el código a su teléfono");
		this.props.dispatch({
			type: 'SET_CODE_SEND',
			payload: moment().format('YYYY-MM-DD HH:mm:ss')
		})
	}

	logout = () => {
		Globals.confirm('¿Desea cerrar sesión?',() => {
			this.props.dispatch({
				type: 'SET_USER',
				payload: null
			});
			this.props.history.replace('/login');
			Globals.quitLoading();
		});
	}
	
	render() {
		return (
			<div id="register" className="validation-user">
				<div className="container-black">
					<div className="row container-form">
						<div className="col-lg-5 container-bg" style={{ backgroundImage: `url(${ LoginImage2 })` }}></div>
						<div id="form-validate" className="col-lg-7">
							<div className="container-finish">
								<div className="container-row">
									<div className="row">
										<div className="col-md-12">
											<div className="row-title">
												<h3 className="title-green">Finalizar Registro</h3>
											</div>
										</div>
										<div className="col-md-12">
											<p className="subtitle-validate">Tu registro esta casi listo, introduce el código de confirmación que envíamos a tu número de teléfono para continuar.</p>
										</div>
										<div className="col-md-12">
											{
												this.state.response != RESPONSES.SUCCESS && (
													<form className="container-code" onSubmit={ this.validate }>
														<Input
															type="number"
															color="white"
															value={ this.state.form.phone }
															name="phone" 
															placeholder="Teléfono"
															onChange={ this.change } />
														<Input
															type="number"
															maxLength={ 6 }
															color="white"
															value={ this.state.form.code }
															name="code" 
															placeholder="Introduce el código aquí"
															onChange={ this.change } />
														<Button type="submit" className="button-validate">
															Validar
														</Button>
													</form>
												)
											}
											{
												this.state.response != RESPONSES.SUCCESS && (this.props.code_send_at == null || moment().subtract(2,'minutes').format('YYYY-MM-DD HH:mm:ss') > this.props.code_send_at) && (
													<div className="text-center">
														<a href="#" className="resend-code" onClick={ this.resend }>Reenviar código</a>
													</div>
												)
											}
											{
												this.state.response != null && (
													<p className={ `response top ${ this.state.response == RESPONSES.SUCCESS ? 'response-blue' : 'response-red' }` }> {
														// @ts-ignore 
														this.getResponse(this.state.response)
													}</p>
												)
											}
										</div>
										<div className="col-md-12">
											{
												this.state.response == RESPONSES.SUCCESS && (
													<div className="text-center container-input container-btn-submit">
														<Button type="button" onClick={ this.submit }>
															Finalizar
														</Button>
													</div>
												)
											}
										</div>
									</div>
								</div>
								<div className="logout" onClick={ () => this.logout() }>
									Iniciar sesión con otro usuario
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user,
		code_send_at: state.code_send_at
	}
})(Register);